<template>
  <div class="wz-box">
    <div class="content">
    <div class="wz-left">
      <p>飞峡雨</p>
      <p class="background"></p>
    </div>
    <section>
      <div class="wz-main">
        <nav>用户登录</nav>
        <div class="wz-bck">
          <el-form ref="loginform"  :model="form"  :rules="login"   label-width="80px">
            <el-form-item prop="name" class="wz-itme">
              <el-input placeholder="用户名" prefix-icon="el-icon-s-custom" clearable v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item prop="word" class="wz-itme">
              <el-input type="password" placeholder="密码" class="wz-put"  clearable   prefix-icon="el-icon-s-goods"   show-password v-model="form.word"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="wz-bcks">
          <el-button type="primary"  @click="logins" :loading="ConfirmLoading" >登录</el-button>
          <!-- <el-button type="button" size="medium" class="wz-btn" @click="resplace">重置</el-button> -->
        </div>
        <div class="wz-authorization">
          <span>飞连登录</span>
          <img src="../../assets/login_images/feilian.jpg" alt="" @click="authorization" :class="[isRotate?'rotate':'rotate1']"> 
        </div>
      </div>
    </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRotate: false,
      ConfirmLoading: false,
      mark:'',
      form: {
        name: "",
        word: "",
      },
      // url:"http://localhost:8080/empty?code=ZsdPAOLsDTxgADzmgcrWQwOoacAiqRwXkWyAdtsb&state=",
      login: {
        name: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 8 个字符", trigger: "blur" },
        ],
        word: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {min: 6, max: 15, message: "长度在 6 到 15 个字符",trigger: "blur"},
        ],
      },
    };
  },
  
  methods: {
     async authorization() {
	      this.isRotate = true;
         this.$message({
          message: '授权中',
          iconClass:'el-icon-loading',
          type: 'success'
        });
        const to = await this.$http.post(this.$tokenUrl, null);
        console.log("我有token了");
        if (to.statusText === "OK") {
        sessionStorage.setItem("Authorization", to.data.data);
				this.isRotate = false
        }
        const res = await this.$http.get("/oauth2/authorization");
        window.location.replace(res.data.data.url);
          // const res = await this.url
          // window.location.replace(res);
    }, 

    resplace() {
      this.$refs.loginform.resetFields();
    },

    async logins() {
      this.$refs.loginform.validate(async (valde) => {
        if (!valde) return;
        if(this.form.name!="admin" || this.form.word!="admin123" ) return this.$message({message: "用户不存在/密码错误", type: "error"})
        this.ConfirmLoading = true
        const res = await this.$http.post(this.$tokenUrl, null);
        if (res.statusText === "OK") {
          sessionStorage.setItem("Authorization", res.data.data);
        }
         let FxyUser={
          user_id:"",
          user_name:this.form.name,
          resource: [{
          role_name: "财务",
          permissions: ["查看K3报表","发送短信"]
          }]
        }
        sessionStorage.setItem("userInfo",JSON.stringify(FxyUser))
        window.sessionStorage.setItem("isLogin", true);
        let obj=JSON.parse(window.sessionStorage.getItem("userInfo"))
        let permissions = obj.resource;
          permissions.forEach((item)=>{
            if(item.permissions.length > 1){
           this.$router.push('/send-management')
        }else{
          item.permissions.forEach((per)=>{
              if(per === '查看K3报表'){
                 this.$router.push("/financialStatements")
              }else{
                 this.$router.push('/send-management')
              }
            })
        }
            if(item.permissions.length < 1){
            this.$router.push("/404")
          }
          })
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.wz-box {
  height: 90%;
  // background-color: #2d3a4b;
  // background: url("../../assets/login_images/dlbj.png");
  display: flex;
	justify-content: center;
	align-items: center;
  .content{
    height: 400px;
    border: 1px solid rgb(226, 225, 225);
    border-radius: 10px;
     display: flex;
  align-items: center;
  justify-content: center;
  }
  .wz-left{
    width: 150px;
    height: 400px;
    background: rgb(0, 110, 255);
    text-align: center;
    overflow: hidden;
     border-top-left-radius: 10px;
     border-bottom-left-radius: 10px;
    box-sizing: border-box;
    p{
      font-size: 28px;
      color: #ffffff;
      line-height: 184px;
      font-weight: 500; 
    }
    .background{
      width: 150px;
      height: 200px;
      background-image: url("../../assets/login_images/login.svg");
      background-size: 278px 212px;
      background-position: 50% -16%;
    }
  }
  section {
    .wz-main{
         margin-top: 66px;
    }
    /deep/.el-input--small .el-input__inner {
    height: 42px;
    line-height: 42px;
}
.el-button--small {
  padding: 12px 6px;
    font-size: 14px;
    border-radius: 4px;
    width: 94%;
    background: rgb(0, 110, 255);;
}
    width: 400px;
    height: 400px;
    border-radius: 3px;
  nav {
    text-align: center;
    line-height: 50px;
    margin-bottom: 30px;
    width: 100%;
    height: 50px;
    font-weight: 400;
    font-size: 24px;
    color: rgb(29, 33, 41);
    }
  .wz-bck {
      .wz-put {
        width: 100%;
        margin-top: 30px;
      }
      .wz-itme {
        width: 90%;
        padding-left: 24px;
      }
    }
  .wz-bcks {
    width: 96%;
    box-sizing: border-box;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    }
  }
}
.wz-bck /deep/ .el-form-item__content {
  margin: 0px !important;
}
.el-form-item {
  margin: 0px !important;
}
.wz-authorization /deep/  {
  height: 40px;
  float: right;
  margin-top: 20px;
  margin-right: 10px;
  // display: none;
}
.wz-authorization span{
  font-size: 16px;
  color: #666;
  margin-left: 20px;
  margin-right: 10px;
} 
.wz-authorization img{
  width: 25px;
  height: 25px;
  margin-bottom: -5px;
  text-align: center;
  cursor: pointer;
}
.refresh {
	position: absolute;
	left: 50%;
	bottom: 4px;
	transform: translateX(-50%);
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	text-align: center;
	line-height: 40px;
	background: #fff;
	box-shadow: 0px 1px 10px 3px #eaeaea;
}
.rotate {
	animation: mymove 1s infinite;
	-webkit-animation: mymove 1s infinite;
}
@keyframes mymove {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}



</style>